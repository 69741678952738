import { useState, useContext, useRef } from "react";
import * as XLSX from 'xlsx';
import AuthContext from "./Context/AuthProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import { axiosPrivate } from "../Components/Api/axios";

function NumSubmit() {

    const { subAlert, setSubAlert } = useContext(AuthContext);
    const inputRef = useRef(null);
    const [input, setInput] = useState(null);
    const [inpKind, setInpKind] = useState(null);
    const [inpType, setInpType] = useState("tel");
    const [inpShow, setInpShow] = useState(false);
    const [resCode, setResCode] = useState(null);

    // onchange states
    const [excelFile, setExcelFile] = useState(null);
    const [typeError, setTypeError] = useState(null);

    //submit state
    const [excelData, setExcelData] = useState(null);

    //onchange event
    const handleFile = (e) => {
        let fileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
        let selectedFile = e.target.files[0];
        if (selectedFile) {
            if (selectedFile && fileTypes.includes(selectedFile.type)) {
                setTypeError(null);
                let reader = new FileReader();
                reader.readAsArrayBuffer(selectedFile);
                reader.onload = (e) => {
                    setExcelFile(e.target.result);
                }
            } else {
                setTypeError('لطفا فقط فایل اکسل وارد کنید.');
                setExcelFile(null);
            }
        } else {
            console.log('Please select');
        }
    }

    //submit event
    const handleFileSubmit = (e) => {
        e.preventDefault();
        if (excelFile !== null) {
            const workbook = XLSX.read(excelFile, { typeError: 'buffer' });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const data = XLSX.utils.sheet_to_json(worksheet);
            setExcelData(data);
            setSubAlert(true);
            inputRef.current.value = "";
            console.log(excelData);
        }
    }

    //single submit
    const sinInputRef = useRef(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setSubAlert(false);
            const response = await axiosPrivate.post(`/add/${inpKind}`, { input });
            setResCode(response?.data?.code);
            setSubAlert(true);
            console.log(typeof(input));
            console.log(response);
            sinInputRef.current.value = '';
            setInput('');
            console.log(inpKind);
        } catch (err) {
            console.error(err);
            // navigate('/sign-in', { state: { from: location }, replace: true });
        }
    };
    return (
        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 h-screen overflow-y-auto scroll-design'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                    <section className="sub-num">
                        <span className='inline-block mr-4 text-blue-800 text-lg ml-2'><FontAwesomeIcon icon={faClipboardQuestion} /></span>
                        <h5 className="inline-flex items-center">معرفی شماره</h5>
                        <p className='  px-8 text-sm md:text-base'>
                            برای انجام استعلام در آینده نیاز است تا ابتدا شماره مورد نظر را در این صفحه به سیستم معرفی کنید.
                        </p>
                        <div className="flex items-center px-8">
                            <p className="m-0">نوع ورودی را مشخص کنید:</p>
                            <ul className="m-0 flex">
                                <li onClick={() => { setInpKind("mobile"); setInpShow(true); setInpType("tel"); console.log(inpType) }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "mobile" ? "bg-yellow cursor-default" : "bg-light-green"} rounded-sm select-none transition-all duration-200 ease-in`}>شماره موبایل</li>
                                <li onClick={() => { setInpKind("id"); setInpShow(true); setInpType("number"); console.log(inpType) }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "id" ? "bg-yellow cursor-default" : "bg-light-green"} rounded-sm select-none transition-all duration-200 ease-in`}>شناسه کاربری</li>
                                <li onClick={() => { setInpKind("username"); setInpShow(true); setInpType("string") }} className={`p-2 hover:bg-yellow text-white text-sm mr-1  ${inpKind === "username" ? "bg-yellow cursor-default" : "bg-light-green"} rounded-sm select-none transition-all duration-200 ease-in`}>نام کاربری</li>
                            </ul>
                        </div>
                        {inpShow && <div className="mt-4"><div className="lg:w-1/2 inq-holder">
                                <div className="mx-auto text-center">
                                    <form onSubmit={handleSubmit}>
                                        <label className="ml-2">ثبت ورودی:</label>
                                        <input type={inpType} required ref={sinInputRef} title="شماره موبایل را به صورت صحیح وارد کنید." onChange={(e) => setInput(e.target.value)} className="border-2 border-bg-light-green w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                                        <button type="submit" className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer">ثبت</button>
                                    </form>
                                </div>
                            </div>

                                <div className="lg:w-1/2 mx-auto mt-3">
                                    <div className="mx-auto text-center">
                                        <form onSubmit={handleFileSubmit}>
                                            <label className="ml-2">ثبت انبوه:</label>
                                            <input required type="file" ref={inputRef} onChange={handleFile} className=" w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                                            <button type="submit" className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-4 mt-2 cursor-pointer">ثبت</button>
                                            {typeError && (
                                                <div className="alert" role="alert">{typeError}</div>
                                            )}
                                        </form>
                                    </div>
                                </div>
                                {
                                    resCode==200 ? <p className=" bg-green-200 p-2 w-fit mx-auto text-center rounded mt-8 transition-all duration-200 ease-in select-none" style={subAlert ? { "visibility": "visible", "opacity": 1 } : { "visibility": "hidden", "opacity": 0 }}>ورودی ثبت شد</p>
                                    : <p className=" bg-red-200 p-2 w-fit mx-auto text-center rounded mt-8 transition-all duration-200 ease-in select-none" style={subAlert ? { "visibility": "visible", "opacity": 1 } : { "visibility": "hidden", "opacity": 0 }}>خطا در ثبت ورودی</p>
                                }
                            </div>}
                    </section>
                </div>
            </div>
        </>
    )

}

export default NumSubmit

import { useContext } from "react";
import AuthContext from "./Context/AuthProvider";
import Header from "./Header";
import Result from "./Results";
import InpInqWhatsapp from "./InpInqWhatsapp";

function WhatsappDet() {

    const { detShow } = useContext(AuthContext);

    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 h-screen overflow-y-auto overflow-x-hidden scroll-design'>
                    <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                        {detShow ? <InpInqWhatsapp /> : <Result />}
                    </div>
                </div>
            </section>
        </>
    )

}

export default WhatsappDet

import { useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { axiosPrivate } from "../Components/Api/axios";
import SinUser from "./SinUser";

const Users = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = Math.ceil(users.length / itemsPerPage);

    useEffect(() => {
        const controller = new AbortController();
        const getUsers = async () => {
            try {
                const response = await axiosPrivate.get('/auth/user', {
                    signal: controller.signal
                });
                setUsers(response.data.data);
            } catch (err) {
                setError(err.message); // Set error message
            } finally {
                setLoading(false);
            }
        };
        getUsers();
        return () => controller.abort();
    }, []);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const displayedUsers = users.slice(startIndex, endIndex);

    return (
        <>
            {loading ? (
                <p>در حال بارگذاری...</p> 
            ) : error ? (
                <p className="text-red-500">{error}</p>
            ) : (
                <div>
                    {displayedUsers.map(user => <SinUser key={user.id} user={user} />)}
                    {totalPages > 1 && (
                        <ReactPaginate
                            pageCount={totalPages}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default Users;
import React, { useState } from 'react';
import CreateUserButton from './formdata.js'; // Import your CreateUserButton
import Modal from './Modal'; // Import the Modal component

const MainComponent = () => {
    const [isModalOpen, setModalOpen] = useState(false);
    const user = {
      role: 'admin', // Change this to 'user' to test different behavior
      };

    const openModal = () => {
        setModalOpen(true); // Open the modal
    };

    const closeModal = () => {
        setModalOpen(false); // Close the modal
    };
    
      

    return (
        <div>
        {user.role === 'admin' && (
                <button onClick={openModal}>ایجاد کاربر</button>
            )}
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <CreateUserButton /> {/* Render your CreateUserButton inside the modal */}
            </Modal>
        </div>
    );
};

export default MainComponent;
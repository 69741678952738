import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from './Hooks/useAuth';

const RequireAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        // auth?.access
        localStorage.getItem("access")
            ? <Outlet />
            : <Navigate to="/sign-in" state={{ from: location }} replace />
    );
}

export default RequireAuth;

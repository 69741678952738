
const Missing = () => {
  return (
    <div>
      you are in the wrong page
    </div>
  )
}

export default Missing

import { useContext, useState, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import AuthContext from './Context/AuthProvider';
import { faCheckSquare, faCross, faQuestionCircle, faXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { axiosPrivate } from "../Components/Api/axios";
import contactImg from '../img/contact.svg';
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import Flag from 'react-world-flags';


function FollowerDet() {

    const { setShow, show, userName } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [userID, setUserId] = useState();
    const [checkInfo, setCheckInfo] = useState();
    const [followKind, setFollowKind] = useState("follower");
    const [currentPage, setCurrentPage] = useState(0);
    const [id, setID] = useState();
    const [followCheck, setFollowCheck] = useState();
    const [sID, setSId] = useState();

    //Checking the presence in DB
    useEffect(() => {

        setID(userName);

        let isMounted = true;
        const controller = new AbortController();

        const checkList = async () => {
            try {
                setLoading(true);
                const resp = await axiosPrivate.get(`/check/${followKind}/${userName}`, {
                    signal: controller.signal
                });
                setFollowCheck(resp?.data?.code);
                console.log(resp);
                console.log(resp?.data?.code);
                setSId(resp?.data?.data[0]?.user_id);
                console.log(sID);
                isMounted && setCheckInfo(resp?.data?.data[0]);
                // setLoading(false);
                console.log(followCheck);
                console.log(id);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        console.log(id);
        checkList();

        console.log(sID);
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [followKind, userName])

    //getting the lists of followers and followings from DB
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                // setLoading(true);
                const response = await axiosPrivate.get(`/get/${followKind}/${sID}/${currentPage + 1}`, {
                    signal: controller.signal
                });
                isMounted && setUserId(response?.data?.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        console.log(followCheck);
        console.log(sID);
        followCheck == 200 && getList();
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [followKind, currentPage, sID])

    //Adding a new id to DB
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                // setLoading(true);
                const res = await axiosPrivate.post(`/add/follower`, { id });
                const resp = await axiosPrivate.post(`/add/following`, { id });
                setLoading(false);
                res?.data?.code == 200 && setShow(true);
                res?.data?.code == 200 && alert("ورودی در سیستم وجود ندارد و  به صورت خودکار اضافه شد.");
                console.log(res?.data?.code);
                console.log(resp);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };

        followCheck == 300 && MySubmit();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [id, followCheck]);

    //Pagination
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 30;

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = userID?.slice(startIndex, endIndex);

    useEffect(() => {
        setTotalPages(Math?.ceil(checkInfo?.max_page));
    }, [subset]);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    // const download = e => {
    //     console.log(e.target.href);
    //     fetch(checkInfo?.excel_export, {
    //         method: "GET",
    //         headers: {
    //             'Access-Control-Allow-Origin': '*',
    //             'access-control-allow-methods': '*',
    //             'access-control-allow-origin': '*',
    //         }
    //     })
    //         .then(response => {
    //             response.arrayBuffer().then(function (buffer) {
    //                 const url = window.URL.createObjectURL(new Blob([buffer]));
    //                 const link = document.createElement("a");
    //                 link.href = url;
    //                 link.setAttribute("download", "file.xlsx"); //or any other extension
    //                 document.body.appendChild(link);
    //                 link.click();
    //             });
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    // };

    return (
        <>
            <div className="transition-all duration-200 ease-in" style={show ? { display: "none", opacity: 0, height: 0 } : { display: "block", opacity: 1, height: "auto" }}>
                <div className="mb-2">
                    <span className='inline-block mr-4 text-green-800 text-lg ml-2'><FontAwesomeIcon icon={faCheckSquare} /></span>
                    <h5 className="inline-flex items-center"><span>نتیجه استعلام</span><span className="text-lg mr-1">(شناسه: {id})</span></h5>
                </div>
                <div className="flex justify-between items-center">
                    {/* <p className=' px-8'>
                        نتیجه استعلام مورد نظر شما به شرح زیر است.
                    </p> */}
                </div>
                {loading ? <div className='flex'>
                    < p className=' text-right text-lg mt-2 pr-6' >در حال بارگذاری...</p>
                </div>
                    : <div className=" text-center text-2xl">
                        <div className='pl-8 flex justify-between'>
                            <div className="flex button-holder">
                                {
                                    checkInfo?.follower_count && <button className={`flex items-center py-1 px-2 hover:bg-yellow text-white text-base mr-1 ${followKind === "follower" ? " bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`} onClick={() => { setFollowKind("follower"); setCurrentPage(0) }}><span className="counter-circle bg-middle-green text-xs m-1">{checkInfo?.follower_count ? checkInfo?.follower_count : "?"}</span><span className="text-sm"> Followers</span></button>
                                }
                                {
                                    checkInfo?.following_count && <button className={`py-1 px-2 hover:bg-yellow text-white text-base mr-1 ${followKind === "following" ? "bg-dark-blue cursor-default" : "bg-light-green"} rounded select-none transition-all duration-200 ease-in`} onClick={() => { setFollowKind("following"); setCurrentPage(0) }}><span className="counter-circle bg-middle-green text-xs m-1">{checkInfo?.following_count ? checkInfo?.following_count : "?"}</span><span className="text-sm"> Following</span></button>

                                }
                                <button className={`py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-light-green rounded select-none transition-all duration-200 ease-in`} onClick={() => setShow(true)}>بازگشت</button>
                            </div>
                            {
                                !loading ? <div className='pr-6 flex items-center justify-between'>
                                    {checkInfo?.excel_export && <div className="mb-1 ml-1">
                                        {
                                            checkInfo?.status === 3 ? <a href={checkInfo?.excel_export} download>
                                                <button className="py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-green-500 rounded select-none transition-all duration-200 ease-in">دانلود اکسل</button>
                                            </a>
                                                : <div className="py-1 px-2 text-white text-base mr-1 bg-red-500 rounded select-none transition-all duration-200 ease-in">استعلام کامل نشده است</div>
                                        }
                                    </div>}
                                    <div className="">
                                        {
                                            // followKind == "follower" &&
                                            userID?.length && <ReactPaginate
                                                className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                                                pageCount={totalPages}
                                                onPageChange={handlePageChange}
                                                forcePage={currentPage}
                                                previousLabel={"<<"}
                                                nextLabel={">>"}
                                                breakLabel={"..."}
                                                containerClassName={"pagination-container"}
                                                activeClassName={"active-page"}
                                            />
                                        }
                                    </div>
                                </div>
                                    : ''
                            }
                        </div>
                        {!userID ? <div className=" text-lg">در حال حاضر اطلاعات قابل نمایشی وجود ندارد.</div> :
                            <div className="table-responsive mt-4 inner-scroll overflow-y-auto pl-2">
                                <table className="table border-2">
                                    <thead className=" bg-middle-green sticky -top-1">
                                        <tr>
                                            <th className="text-base">
                                                آواتار
                                            </th>
                                            <th className="text-base bg-middle-green">
                                                نام کاربری
                                            </th>
                                            <th className="text-base">
                                                شناسه کاربری
                                            </th>
                                            <th className="text-base">
                                                نام و نام خانوادگی‌
                                            </th>
                                            <th className="text-base">
                                                نوع اکانت
                                            </th>
                                            <th className="text-base">
                                                لینک اینستاگرام
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {userID?.map((item, i) => (
                                            <tr key={i} className="">
                                                <td className="text-lg flag-holder">
                                                    <div className="flex justify-around items-center">
                                                        <span className="">
                                                            <a href={item?.profile_image} target="_blank">
                                                                <img className="block mx-auto" src={contactImg} width={25} />
                                                            </a>
                                                        </span>
                                                        <span>
                                                            {
                                                                item?.has_mobile ? <Flag code={`${(item?.code_mobile === "+98" && "364") || (item?.code_mobile === "+11" && "840")}`} fallback={<span className='inline-block text-gray-800 text-xl mx-auto mt-1'><FontAwesomeIcon icon={faQuestionCircle} /></span>} />
                                                                    : <span className='inline-block text-red-500 text-xl mx-auto mt-1'><FontAwesomeIcon icon={faXmarkCircle} /></span>
                                                            }
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="text-base">{item?.username}</td>
                                                <td className="text-base">{item?.user_id}</td>
                                                <td className="text-base">{item?.name}</td>
                                                <td className="text-base"><div className={item?.public == 0 ? `text-red-700` : 'text-green-700'}>{item?.public == 0 ? 'Private' : "Public"}</div></td>
                                                <td className="text-base insta-holder-td"><a href={`https://www.instagram.com/${item?.username}/`} target="_blank"><span className='inline-block fill-gradient-instagram-text text-white mx-auto'><FontAwesomeIcon icon={faInstagram} /></span></a></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>}
                    </div>
                }
            </div>
        </>
    )

}

export default FollowerDet

import React, { useState } from 'react';
import { axiosPrivate } from "../Components/Api/axios";
import sampleUploadFile from "../xlx/sample-mobile.xlsx"; // Path to your sample file

const BulkInp = () => {
    const [file, setFile] = useState(null);
    const [notification, setNotification] = useState(''); // Notification state
    

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleFileSubmit = async (e) => {
        e.preventDefault();

        if (file) {
            const formData = new FormData();
            formData.append('file', file); // Append the selected file with the key 'file'

            try {
                const token = localStorage.getItem('token'); // Adjust based on how you manage tokens

                const response = await axiosPrivate.post('/import/mobile', formData, {
                    headers: { 
                        Authorization: `Bearer ${token}`, // Add the token to the header
                        'Content-Type': 'multipart/form-data' // Set the content type for file upload
                    }
                });
                
                console.log('Upload successful:', response);
                setNotification('بارگذاری با موفقیت انجام شد. در حال رفرش صفحه...');
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            } catch (error) {
                console.error('Upload error:', error);
                if (error.response) {
                    console.error('Response data:', error.response.data);
                }
                setNotification('بارگذاری با خطا مواجه شد. لطفا مجددا تلاش کنید.');
            }
        } else {
            console.log('No file selected');
        }
    };

    return (
        <div className="lg:w-1/2 mx-auto mt-3">
            <div className="mx-auto text-center">
                <form onSubmit={handleFileSubmit}>
                    <input 
                        type="file" 
                        className="w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" 
                        onChange={handleFileChange} 
                    ></input>
                    <button 
                        type="submit" 
                        className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer"
                    >
                        بارگذاری
                    </button>
                </form>
                
                {notification && (
                    <div className="mt-4 text-green-600">
                        {notification}
                    </div>
                )}
                
                <div className="flex justify-center mt-3">
                                <p> لطفاً برای بارگذاری صحیح اطلاعات، فایل را طبق الگو پر کنید.</p>
                            </div>
                <div className="flex justify-center">
                    <a 
                        href={sampleUploadFile} 
                        download 
                        className="text-blue-600 hover:underline mr-2"
                    ><button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-1" role="button">دانلود فایل نمونه آپلود</button>
                        
                    </a>
                </div>
            </div>
        </div>
    );
};

export default BulkInp;






import { useState, useEffect } from "react";
import Header from "./Header";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faXmark, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from 'react-paginate';
import { axiosPrivate } from "../Components/Api/axios";
import contactImg from '../img/contact.svg';
//changes 
import persian from "react-date-object/calendars/persian";
import persian_fa from "react-date-object/locales/persian_fa";
import { useForm, Controller } from "react-hook-form";
import moment from 'moment-jalaali';
import  '../CSS/datepicker.css'
import DatePicker, { DateObject } from 'react-multi-date-picker';
//changes 

function WhatsappHistory() {

    const [loading, setLoading] = useState(false);
    const [numList, setNumList] = useState();
    const [inpKind, setInpKind] = useState("id");
    const [fullBio, setFullBio] = useState();
    const [openBio, setOpenBio] = useState(false);
    const [hisKind, setHisKind] = useState("insta");
    const [inputValue, setInputValue] = useState('');   // State for user input
    const [showModal, setShowModal] = useState(false);  // State for modal visibility
       //changes
       const [dateStart, setDateStart] = useState('');
       const [dateEnd, setDateEnd] = useState('');
       const { control, handleSubmit, formState: { errors } } = useForm();

    const handleOpenbio = () => {
        setOpenBio(true);
    }

    // Close Modal
    const handleClosebio = () => {
        setOpenBio(false)
    };

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/list/mobile`, {
                    signal: controller.signal
                });
                isMounted && setNumList(response?.data?.slice(0).reverse());
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getList();
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [])
    console.log(numList);

    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = 10;

    console.log(totalPages);

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const subset = numList?.slice(startIndex, endIndex);

    useEffect(() => {
        setTotalPages(Math.ceil(numList?.length / itemsPerPage));
    }, [subset]);

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const download = e => {
        console.log(e.target.href);
        fetch(e.target.href, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "image.jpeg"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleDelete = async (input) => {
        try {
            const response = await axiosPrivate.post('/remove/mobile', { input });
            if (response.status === 200) {
                setNumList((prevList) => prevList.filter(item => item.mobile !== input));
            }
        } catch (err) {
            console.error("Error deleting item:", err);
        }
    };
    const handleExportAll = async () => {
        try {
            const formData = new FormData();
            formData.append("count", ""); // Optional or define as needed
            formData.append("all", true); // Requesting all records
  
            const exportResponse  = await  axiosPrivate.post(`/export/whatsapp`, formData, {
  
            });
            console.log("Export Response:", exportResponse .data);
            const fileUrl = exportResponse?.data?.file;
  
            if (fileUrl) {
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', 'instagram_records.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error("File URL not found in export response.");
                alert("No records found for the export request.");
            }
        } catch (error) {
            console.error("Export Error:", error);
            alert("An error occurred while exporting data. Please try again.");
        }
    };
    // دانلود تمام اطلاعات

    const handleDownload = async () => {
        try {
            setLoading(true);
            if (!inputValue && (!dateStart || !dateEnd)) {
                alert(".لطفا یک فیلد را تکمیل کنید");
                setLoading(false);
                return;
            }
             // Convert Jalali dates to Gregorian format
             const startDate = dateStart ? moment(dateStart.toDate()).format('YYYY/MM/DD') : null; // Convert to Gregorian
             const endDate = dateEnd ? moment(dateEnd.toDate()).format('YYYY/MM/DD') : null; // Convert to Gregorian


            // Prepare the request body
            const requestBody = {
                count: inputValue ? inputValue : undefined, // only include if not null
                date_start: startDate, 
                date_end: endDate
            };

            // Send the request to the backend
            const response = await axiosPrivate.post(`/export/whatsapp`, requestBody);
            
            // Parse the JSON response to get the file URL
            const fileUrl = response?.data?.file;

            if (fileUrl) {
                // Trigger download from the file URL
                const link = document.createElement('a');
                link.href = fileUrl;
                link.setAttribute('download', 'record.xlsx'); // Customize the file name if necessary
                document.body.appendChild(link);
                link.click();
                link.remove();
            } else {
                console.error("File URL not found in response.");
            }
        } catch (err) {
            console.error("Error:", err);
        } finally {
            setLoading(false);
            setShowModal(false);  // Close modal after downloading
        }
    };

    return (
        <>
            <Header />
            <section className="dark:bg-stone-900 dark:text-gray-100 transition-all duration-100 ease-in h-screen">
                <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden'>
                    <div className="md:col-span-7 md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                        <span className='inline-block place-content-center mr-4 text-green-800 dark:text-green-200 text-lg ml-2'><FontAwesomeIcon icon={faClockRotateLeft} /></span>
                        <h5 className="inline-flex items-center">تاریخچه استعلام</h5>
                        <div className="flex justify-end">
                            <div>
                                {/* Button to trigger the popup */}
                                <button
                                    onClick={() => setShowModal(true)}
                                    className="py-1 px-2 hover:bg-yellow text-white text-base mr-1 bg-green-500 rounded select-none transition-all duration-200 ease-in"
                                >
                                    دانلود خروجی
                                </button>

                                {/* Modal for user input */}
                                {showModal && (<div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
                        <div className="bg-white p-4 rounded w-96 shadow-lg">
                   <form onSubmit={handleSubmit(handleDownload)} >
                              <h2 className="text-lg font-bold mb-4">چند رکورد آخر دانلود شود؟</h2>
                              <input
                                    type="number"
                                    value={inputValue}
                                    id='num'
                                    onChange={(e) => setInputValue(e.target.value)}
                                    className="border border-gray-300 p-2 rounded mb-4 w-full"
                                    placeholder="تعداد رکورد"
                              />
                              <div className="container">
                                    
                                    
                              </div>
                              <span className="block mb-1">تاریخ شروع:</span>
                              <Controller
                                    control={control}
                                    name="dateStart"
                                    
                                    render={({ field }) => (
                                          <DatePicker
                                          placeholder="تاریخ شروع را انتخاب کنید"
                                          value={dateStart}
                                          id='date1'
                                          onChange={(date) => {
                                                setDateStart(date);
                                                console.log("Selected Start Date:", date);
                                                field.onChange(date);
                                                
                                          }}
                                          format={"YYYY/MM/DD"}
                                          calendar={persian}
                                          locale={persian_fa}
                                          calendarPosition="bottom-right"
                                          />
                                    )}
                                    />
                              <span className="block mb-1">تاریخ پایان:</span>
                                    <Controller
                                          control={control}
                                          name="dateEnd"
                                          render={({ field }) => (
                                                <DatePicker
                                                id='date2'
                                                placeholder="تاریخ پایان را انتخاب کنید"
                                                value={dateEnd}
                                                onChange={(date) => {
                                                      setDateEnd(date);
                                                      console.log("Selected End Date:", date); // Log the selected date
                                                      field.onChange(date);
                                                }}
                                                format={"YYYY/MM/DD"}
                                                calendar={persian}
                                                locale={persian_fa}
                                                calendarPosition="bottom-left"
                                                
                                                />
                                          )}
                                    />
                        
                        {errors.dateStart && <span>تاریخ شروع الزامی است</span>}
                        {errors.dateEnd && <span>تاریخ پایان الزامی است</span>}
                        <div className="flex justify-center">            
                        <button type="submit" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2">دانلود</button>
                        <button
                            onClick={() => setShowModal(false)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2">
                            انصراف
                        </button>
                              
                        </div>
            </form>
                <div className="flex justify-center">
                <button onClick={handleExportAll} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-1">
                        دانلود تمام اطلاعات
                </button>
                </div>
            </div>
            </div> )}
                            </div>
                            <div className='pl-8 flex justify-end'>
                                {
                                    !loading ? <div className='pr-6'>
                                        {numList?.length > 10 ? <ReactPaginate
                                            className='pagination-container flex items-center p-0 m-0 rounded-md w-fit'
                                            pageCount={totalPages}
                                            onPageChange={handlePageChange}
                                            forcePage={currentPage}
                                            previousLabel={"<<"}
                                            nextLabel={">>"}
                                            breakLabel={"..."}
                                            containerClassName={"pagination-container"}
                                            activeClassName={"active-page"}
                                        />
                                            : ''
                                        }
                                    </div>
                                        : ''
                                }
                            </div>
                        </div>
                        <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md px-2 inner-scroll-250 overflow-y-auto">
                            {loading ? <div className='flex'>< p className=' text-right text-lg mt-2 pr-6' > در حال بارگذاری...</p></div>
                                : subset?.map((log, i) => (
                                    log?.whatsapp_status === 1 ?
                                        <div key={i} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                            <div className="news-card-header col-span-3">
                                                <div className='flex md:flex-col lg:flex-row justify-between'>
                                                    <div className='flex justify-between items-center mr-6 w-full'>
                                                        <div className='flex flex-col justify-between'>
                                                            <div className="flex">
                                                                <div className="md:ml-4">
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        شماره تماس:
                                                                        {
                                                                            log?.whatsapp[0]?.mobile && <span> {log?.whatsapp[0]?.mobile} </span>
                                                                        }
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">کشور:
                                                                        {log?.whatsapp[0]?.countryCode ? <span> {log?.whatsapp[0]?.countryCode} </span> : " نامشخص "}
                                                                    </p>
                                                                    {/* <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                    شناسه کاربری:
                                                                    {
                                                                        log?.user_id ? <span>{log?.user_id}</span>
                                                                            : log?.status === 2 ? <span> ندارد </span>
                                                                                : log?.status === 1 ? <span>...</span>
                                                                                    : "..."
                                                                    }
                                                                </p> */}
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        نوع اکانت: {log?.whatsapp[0]?.isBusiness === 1 && " بیزینس "}
                                                                        {log?.whatsapp[0]?.isBusiness !== 1 && " شخصی "}
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white bio-holder-his">
                                                                        {
                                                                            log?.whatsapp[0]?.biography ?
                                                                                <>
                                                                                    درباره:
                                                                                    <span className=" cursor-pointer select-none" onClick={() => { handleOpenbio(); setFullBio(log?.whatsapp[0]?.biography) }}>{`${log?.whatsapp[0]?.biography?.length > 50
                                                                                        ? `
                                        ...${log?.whatsapp[0]?.biography?.slice(0, 20)} `
                                                                                        : log?.whatsapp[0]?.biography} `}</span>
                                                                                </> : <>درباره: <span className="text-red-500 font-bold"> ندارد </span></>
                                                                        }
                                                                        {openBio &&
                                                                            <div className='bioWrap'>
                                                                                <div className='fullScreenImage'>
                                                                                    <p className="text-center text-white">
                                                                                        <div className="flex justify-between border-b border-dark-blue">
                                                                                            <p className='flex justify-between text-lg text-white m-0'><span>بیوگرافی‌ کامل</span></p>
                                                                                            <span className=" text-white hover:opacity-70"><FontAwesomeIcon icon={faXmark} className=' cursor-pointer select-none' onClick={handleClosebio} /></span>
                                                                                        </div>
                                                                                        <p className=' overflow-y-auto text-white text-lg p-6 h-4/5'>{fullBio}</p>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="">
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        {
                                                                            log?.whatsapp[0]?.name &&
                                                                            <> نام کاربری:
                                                                                <span> {log?.whatsapp[0]?.name} </span></>
                                                                        }
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:
                                                                        {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.created_at} </span> : " نامشخص "}
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                        آخرین آپدیت:
                                                                        {
                                                                            log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                        }
                                                                    </p>
                                                                    <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                        وضعیت استعلام: <span className={`${(
                                                                            (log?.whatsapp_status === 2) ? 'text-red-500 font-bold' :
                                                                                (log?.whatsapp_status === 1) ? 'text-green-500 font-bold' : ""
                                                                        )
                                                                            }`}>{
                                                                                (
                                                                                    log?.whatsapp_status === 1 ? 'واتساپ دارد' :
                                                                                        'واتساپ ندارد')
                                                                            }</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-col items-center">
                                                            {(log?.whatsapp_status === 1) && <div className="">
                                                                {
                                                                    log?.whatsapp[0]?.profile_image ? <a href={log?.whatsapp[0]?.profile_image} download target="_blank"><img src={log?.whatsapp[0]?.profile_image} alt="تصویر پروفایل" width={100} className="rounded-full" /></a>
                                                                        : <img src={contactImg} alt="تصویر پروفایل" width={100} className="rounded-full" />
                                                                }
                                                            </div>
                                                            }
                                                            <div className="flex items-center justify-center gap-1">
                                                                {/* Delete button */}
                                                                <button className={`flex justify-center items-center py-1 px-2 mt-2 w-28 hover:bg-red-800 text-base text-white bg-red-500 rounded select-none transition-all duration-200 ease-in`} onClick={() => handleDelete(log?.mobile)}>
                                                                        <span>حذف</span>
                                                                        <span className="text-xs ml-1"><FontAwesomeIcon icon={faTrash} /></span>
                                                                    </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className=' w-1/3 flex flex-col items-center justify-center'>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    تاریخ: {log?.date}
                                                </p>
                                                <p className="m-0 text-sm text-right mt-1 text-gray-600 dark:text-white">
                                                    ساعت: {log?.time?.slice(0, 8)}
                                                </p>
                                            </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        : log?.whatsapp_status === 2 ? <div key={i} style={{ "min-height": "134.2px" }} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                            <div className="news-card-header col-span-3">
                                                <div className='flex md:flex-col lg:flex-row justify-between'>
                                                    <div className='flex justify-between items-center mr-6 w-full'>
                                                        <div className='flex flex-col justify-between'>
                                                            <div className="flex">
                                                                <div className="md:ml-4">
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        شماره تماس:
                                                                        {
                                                                            log?.mobile ? <span> {log?.mobile}+ </span>
                                                                                : log?.status === 2 ? <span> ندارد </span>
                                                                                    : log?.status === 1 ? <span>...</span>
                                                                                        : "..."
                                                                        }
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:
                                                                        {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.created_at} </span> : " نامشخص "}
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                        آخرین آپدیت:
                                                                        {
                                                                            log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                        }
                                                                    </p>
                                                                    <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                        وضعیت استعلام: <span className={`${(
                                                                            (log?.whatsapp_status === 2) ? 'text-red-500 font-bold' :
                                                                                (log?.whatsapp_status === 1) ? 'text-green-500 font-bold' : ""
                                                                        )
                                                                            }`}>{
                                                                                (
                                                                                    log?.whatsapp_status === 2 && <span> واتساپ ندارد </span>)
                                                                            }</span>
                                                                    </p>
                                                                </div></div></div>
                                                        <div className="flex items-center justify-center gap-1">
                                                            {/* Delete button */}
                                                            <button className={`py-1 px-2 mt-2 w-28 hover:bg-red-800 text-base text-white bg-red-500 rounded select-none transition-all duration-200 ease-in`} onClick={() => handleDelete(log?.mobile)}><span>حذف</span><span className="text-xs ml-1"><FontAwesomeIcon icon={faTrash} /></span></button>
                                                        </div>
                                                    </div></div></div>
                                        </div> : <div key={i} style={{ "min-height": "134.2px" }} className="w-full bg-gray-50 dark:bg-gray-600 hover:bg-blue-200 transition-all duration-200 ease-in border border-slate-400 rounded-md mb-3 p-3">
                                            <div className="news-card-header col-span-3">
                                                <div className='flex md:flex-col lg:flex-row justify-between'>
                                                    <div className='flex justify-between items-center mr-6 w-full'>
                                                        <div className='flex flex-col justify-between'>
                                                            <div className="flex">
                                                                <div className="md:ml-4">
                                                                    <p className="m-0 text-sm mt-1 text-gray-600 dark:text-white">
                                                                        شماره تماس:
                                                                        {
                                                                            log?.mobile ? <span> {log?.mobile} </span>
                                                                                : log?.status === 2 ? <span> ندارد </span>
                                                                                    : log?.status === 1 ? <span>...</span>
                                                                                        : "..."
                                                                        }
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">تاریخ ثبت:
                                                                        {log?.created_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.created_at} </span> : " نامشخص "}
                                                                    </p>
                                                                    <p className="m-0 mt-1 text-sm text-gray-600 dark:text-white">
                                                                        آخرین آپدیت:
                                                                        {
                                                                            log?.updated_at ? <span className="inline-block" style={{ "direction": "rtl" }}> {log?.updated_at} </span> : " نامشخص "
                                                                        }
                                                                    </p>
                                                                    <p className={`m-0 mt-1 text-sm text-gray-600 dark:text-white`}>
                                                                        وضعیت استعلام: <span className={`${(
                                                                            (log?.whatsapp_status === null) && 'text-blue-800 font-bold'
                                                                        )
                                                                            }`}>{
                                                                                (
                                                                                    log?.whatsapp_status === null && <span> در حال استعلام </span>)
                                                                            }</span>
                                                                    </p>
                                                                </div></div></div></div></div></div>
                                        </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}

export default WhatsappHistory

const SinUser = ({ user }) => {
    if (!user) return null;

    return (
        <div className="profile-container grid grid-cols-6">
            <div className="flex justify-between">
                <div className="flex items-center px-8">
                    <div className="col-span-4 col-start-2 content-center bg-purple-100 p-4 mt-4 rounded-md">
                        <h6 className="m-0">نام کاربر: {user.name}</h6>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            شناسه کاربر: {user.id}
                        </p>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            ایمیل: {user.email}
                        </p>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            دسترسی: {user.role}
                        </p>
                        <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                            تاریخ ساخت: {user.created_at}
                        </p>
                        <div className="mt-4">
                            <h6 className="mb-2">استفاده از برنامه‌ها:</h6>
                            <ul>
                                <li>استفاده از موبایل: {user.mobile_usage}</li>
                                <li>استفاده از واتساپ: {user.whatsapp_usage}</li>
                                <li>استفاده از اینستاگرام: {user.instagram_usage}</li>
                                <li>استفاده از تلگرام: {user.telegram_usage === null ? 'ندارد' : user.telegram_usage}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SinUser;
import { useState, useContext } from "react";
import AuthContext from "./Context/AuthProvider";
import Header from "./Header";
import Result from "./Results";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardUser, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import SinNumInp from "./SinNumInp";
import SinUserNInp from "./SinUserNInp";

const AccInq = () => {

    const { socialCh, setSocailCh, show, inpShow, setmInpShow, accShow } = useContext(AuthContext);
    const [typeShow, setTypeShow] = useState();

    function turnToTelegram() {
        setmInpShow(true);
        setSocailCh('telegram');
        setTypeShow(false);
    }

    function turnToWhatsapp() {
        setmInpShow(true)
        setSocailCh('whatsapp');
    }

    function turnToInsta() {
        setmInpShow(true)
        setSocailCh('insta');
        setTypeShow(false);
    }

    function turnToTwitter() {
        setmInpShow(true)
        setSocailCh('twitter');
        setTypeShow(false);
    }

    function turnToFb() {
        setmInpShow(true)
        setSocailCh('fb');
        setTypeShow(false);
    }

    const actFilter = {
        backgroundColor: "rgb(1, 31, 75)",
        color: "white",
        borderLeftColor: "rgb(1, 31, 75)",
    };

    const dActFilter = {
        // backgroundColor: "transparent"
    }

    return (
        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 h-screen overflow-y-auto overflow-x-hidden scroll-design'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                    <section>
                        {accShow ?
                            <>
                                <span className='inline-block  place-content-center mr-4 text-blue-800 text-lg ml-2'><FontAwesomeIcon icon={faClipboardUser} /></span>
                                <h5 className="inline-flex items-center">استعلام اکانت</h5>
                                <div>
                                    <p className=' px-8 text-sm md:text-base'>
                                        برای استعلام اطلاعات، شبکه اجتماعی و نام کاربری مورد نظر را وارد نمایید.
                                    </p>
                                    <nav className='rounded-lg max-w-fit h-10 my-8 mx-4'>
                                        <ul className='flex flex-col md:flex-row p-0 m-0'>
                                            <li className='text-white grid place-content-center text-base py-2 px-1 w-28 bg-dark-blue md:rounded-r-md select-none cursor-default' onClick={() => { setTypeShow(!typeShow) }}>انتخاب بستر</li>
                                            <li className={`flex relative justify-center items-center text-center text-sm select-none transition-all duration-200 ease-in text-gray-100 bg-light-green w-28 py-2 px-1 md:mr-1 hover:bg-dark-blue ${typeShow ? "mobVis" : "mobHid"}`} style={socialCh === "insta" ? actFilter : dActFilter} onClick={turnToInsta}>اینستاگرام</li>
                                            <li className={`flex relative justify-center items-center text-center text-sm select-none transition-all duration-200 ease-in text-gray-100 bg-light-green w-28 py-2 px-1 md:mr-1 hover:bg-dark-blue ${typeShow ? "mobVis" : "mobHid"}`} style={socialCh === "telegram" ? actFilter : dActFilter} onClick={turnToTelegram}>تلگرام</li>
                                            <li className={`flex relative justify-center items-center text-center text-sm select-none transition-all duration-200 ease-in text-gray-100 bg-light-green w-28 py-2 px-1 md:mr-1 hover:bg-dark-blue ${typeShow ? "mobVis" : "mobHid"}`} style={socialCh === "whatsapp" ? actFilter : dActFilter} onClick={turnToWhatsapp}>واتساپ</li>
                                            <li className={`flex relative justify-center items-center text-center text-sm select-none transition-all duration-200 ease-in text-gray-100 bg-light-green w-28 py-2 px-1 md:mr-1 hover:bg-dark-blue ${typeShow ? "mobVis" : "mobHid"}`} style={socialCh === "twitter" ? actFilter : dActFilter} onClick={turnToTwitter}>توییتر</li>
                                            <li className={`flex relative justify-center items-center text-center text-sm select-none transition-all duration-200 ease-in text-gray-100 bg-light-green w-28 py-2 px-1 md:mr-1 hover:bg-dark-blue md:rounded-l-md ${typeShow ? "mobVis" : "mobHid"}`} style={socialCh === "fb" ? actFilter : dActFilter} onClick={turnToFb}>فیسبوک</li>
                                        </ul>
                                    </nav>
                                    {/* <div className="lg:w-1/2 inq-holder">
                                        <div className="mx-auto text-center">
                                            <form onSubmit={handleSubmit}>
                                                <label className="ml-2">ورود نام کاربری:</label>
                                                <input type="text" onChange={(e) => setUserName(e.target.value)} className="border-2 border-bg-light-green w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                                                <button type="submit" className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer">ثبت</button>
                                            </form>
                                        </div>
                                    </div> */}
                                    {inpShow ? <SinUserNInp /> : ''}
                                </div>
                            </> : <Result />
                        }
                    </section>
                </div>
            </div>
        </>
    )
}

export default AccInq




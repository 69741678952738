import React from 'react';
import { Routes, Route } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import '../CSS/index.css';
import Home from './Home';
import Layout from './Layout';
import LoginPage from './SignIn';
import AccInq from './AccInq';
import NumInq from './NumInq';
import NumSubmit from './NumSubmit';
import Register from './SignUp';
import Missing from './Missing';
import RequireAuth from './RequireAuth';
import RequireNotAuth from './RequireNotAuth';
import Profile from './Profile';
import AccDet from './AccDet';
import InpHistory from './InpHistory';
import TwDet from './TwDet';
import TelDet from './TelDet';
import InstaPost from './InstaPost';
import FollowHistory from './FollowHistory';
import WhatsappDet from './WhatsappDet';
import InstaInq from './InstaInq';
import InstaHistory from './InstaHistory';
import WhatsappHistory from './WhatsappHistory';
import UsersList from './UsersList';
import CreateUserButton from './formdata';
import MainComponent from './MainComponent';

import { Modal } from 'bootstrap';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route element={<RequireNotAuth />}>
          <Route path='/sign-in' element={<LoginPage />} />
          <Route path='/sign-up' element={<Register />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path='/' element={<Home />} />
          <Route path='/social-inquiry' element={<AccInq />} />
          <Route path='/number-inquiry' element={<NumInq />} />
          <Route path='/insta-inquiry' element={<InstaInq />} />
          <Route path='/number-submit' element={<NumSubmit />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/account-details' element={<AccDet />} />
          <Route path='/input-history' element={<InpHistory />} />
          <Route path='/insta-history' element={<InstaHistory />} />
          <Route path='/telegram-details' element={<TelDet />} />
          <Route path='/whatsapp-details' element={<WhatsappDet />} />
          <Route path='/twitter-details' element={<TwDet />} />
          <Route path='/instagram-posts' element={<InstaPost />} />
          <Route path='/instagram-history' element={<FollowHistory />} />
          <Route path='/whatsapp-history' element={<WhatsappHistory />} />
          <Route path='/formdata' element={<CreateUserButton/>}/>
          <Route path='/users-list' element={<UsersList />} />
          <Route path='/modal' element={<Modal/>}/>
          <Route path='/maincomponent' element={<MainComponent/>}/>
        </Route>
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;
import { useRef, useState, useEffect } from 'react';
import useAuth from './Hooks/useAuth';
import jbLogo from '../img/jb-logo.png';
import social from '../img/LandingRedesSocialesl.png';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from './Api/axios';
// const LOGIN_URL = '/api/auth/jwt/create';

const LoginPage = () => {
    const { auth, setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("auth/login",
                { email, password },
                {
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                }
            );
    
            // Extract token, role, username, and email from the response
            const access = response?.data?.accessToken;
            const role = response?.data?.role; // Assuming the role is returned in the login response
            const username = response?.data?.name; // Assuming username is included in the response
            const userEmail = response?.data?.email; // Assuming email is included in the response
    
            // Set the access token, role, username and email in the auth state
            setAuth({ access, role, username, email: userEmail });
    
            // Save the token and role in localStorage
            localStorage.setItem("access", access);
            localStorage.setItem("role", role); // Store role for persistence
            localStorage.setItem("username", username); // Optionally store username
            localStorage.setItem("email", userEmail); // Optionally store email
            
    
            setEmail('');
            setPassword('');
            navigate(from, { replace: true });
        } catch (err) {
            // Handle error response logic here...
            if (!err?.response) {
                setErrMsg('پاسخی از سرور دریافت نشد.');
            } else if (err.response?.status === 400) {
                setErrMsg('نام کاربری یا رمز عبور وارد نشده است.');
            } else if (err.response?.status === 422 || 401) {
                setErrMsg('اطلاعات ورود صحیح نیست');
            } else {
                setErrMsg('ورود با خطا مواجه شد.');
            }
            if (errRef.current) {
                errRef.current.focus();
            }
        }
    }
    

    return (
        <div className='grid bg-grad grid-cols-1 md:grid-cols-2 w-full min-h-screen'>
            <section className='min-w-screen w-full flex justify-center items-center'>
                <form className="Auth-form mx-3 min-w-full w-full max-w-xl py-14" onSubmit={handleSubmit}>
                    <div className="text-center"><img className="img-fluid mb-3 inline" src={jbLogo} alt="logo" width={100} /></div>
                    <div className="px-20">
                        <h3 className="text-center text-zinc-900 font-extrabold mb-4">ورود کاربر</h3>
                        <div className="form-group mt-3 w-80 mx-auto">
                            <label htmlFor="email" className='text-white'>نام کاربری:</label>
                            <input
                                type="text"
                                id="email"
                                className="block w-full py-2 px-3 rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow"
                                ref={userRef}
                                autoComplete="off"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                                required
                            />
                        </div>
                        <div className="form-group mt-3 w-80 mx-auto">
                            <label htmlFor="password" className='text-white'>کلمه عبور:</label>
                            <input
                                type="password"
                                id="password"
                                className="block w-full py-2 px-3 rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                required
                            />
                        </div>
                        <div className="d-grid gap-2 mt-3 w-80 mx-auto">
                            <button className=" bg-middle-yellow text-slate-50 hover:text-middle-yellow outline-2 outline-white hover:bg-white text-lg border-4 border-middle-yellow hover:shadow-middle-yellow rounded select-none transition-all duration-200 ease-in py-1 mt-2">ورود</button>
                        </div>
                        <div className="d-grid gap-2 mt-3 w-80 mx-auto">
                            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                                {errMsg}
                            </p>
                        </div>
                    </div>
                </form>
            </section>
            <div className='hidden md:flex justify-center items-center pl-20'>
                <img src={social} width={"80%"} />
            </div>
        </div>
    )
}

export default LoginPage;
// Single number input component
import { useContext, useState } from "react";
import AuthContext from './Context/AuthProvider';

function SinUserNInp() {

    const { setShow, userName, setUserName, setAccShow } = useContext(AuthContext);

    const handleSubmit = (e) => {
        e.preventDefault();
        setAccShow(false);
        setShow(false);
        console.log(userName);
    };

    return (
        <div className="lg:w-1/2 inq-holder">
            <div className="mx-auto text-center">
                <form onSubmit={handleSubmit}>
                    <label className="ml-2">ورود شناسه یا نام کاربری:</label>
                    <input type="text" onChange={(e) => setUserName(e.target.value)} className="border-2 border-bg-light-green w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                    <button type="submit" className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer">ثبت</button>
                </form>
            </div>
        </div>
    )

}

export default SinUserNInp

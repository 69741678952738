import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from './Hooks/useAuth';

const RequireNotAuth = () => {
    const { auth } = useAuth();
    const location = useLocation();

    return (
        // auth?.access
        !localStorage.getItem('access')
            ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
    );
}

export default RequireNotAuth;

import React from 'react';
import '../CSS/modal.css'; // You can create a CSS file for styling

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null; // Return null if the modal should not be open

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                {children} {/* Render the modal content here */}
            </div>
        </div>
    );
};

export default Modal;
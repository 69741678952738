import { useEffect, useState, useRef } from 'react';
import { axiosPrivate } from "../Components/Api/axios";
import Header from './Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faHashtag, faHeart, faMessage, faUser, faUserCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import useAuth from './Hooks/useAuth';
import contactImg from '../img/contact.svg';

const InstaPost = ({ cat, sent, timing }) => {

    const { searchInput, taging, pageNumber, setPageNumber, subAlert, setSubAlert } = useAuth();
    const [loading, setLoading] = useState(false);
    const [inputLoading, setInputLoading] = useState(false);
    const [searchData, setSearchData] = useState();
    const [filteredResults, setFilteredResults] = useState([]);
    const [pageNum, setPageNum] = useState();
    const [inpShow, setInpShow] = useState();
    const [input, setInput] = useState(null);
    const [inputi, setInputi] = useState(null);
    const [code, setCode] = useState(null);
    const [postCheck, setPostCheck] = useState(null);
    const sinInputRef = useRef(null);

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const getList = async () => {
            try {
                setLoading(true);
                const response = await axiosPrivate.get(`/list/post`, {
                    signal: controller.signal
                });
                isMounted && setSearchData(response?.data?.slice(0).reverse());
                console.log(response?.data);
                // setProgress(Number(numList?.page)/Number(numList?.max_page));
                setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }

        getList();
        console.log(searchData);
        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input])

    // useEffect(() => {
    //     const filteredData = searchData?.filter((fact) => {
    //         return Object.values(fact)
    //             ?.join("")
    //             ?.toLowerCase()
    //             ?.includes(searchInput?.toLowerCase());
    //     });
    //     // setFilteredResults(filteredData);
    //     setFilteredResults(searchData);
    // }, [searchData, searchInput]);

    const [openModal, setOpenModal] = useState(false);
    const [slideNumber, setSlideNumber] = useState(0);
    const handleOpenModal = (i) => {
        setSlideNumber(i)
        setOpenModal(true)
    }

    const handleOpenModalSingle = () => {
        setOpenModal(true)
    }

    // Close Modal
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    function decree() {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    function increa() {
        setPageNumber(pageNumber + 1);
    };

    useEffect(() => {

        let isMounted = true;
        const controller = new AbortController();

        const checkPost = async () => {
            try {
                // setLoading(true);
                const resp = await axiosPrivate.get(`/check/post/${input}`, {
                    signal: controller.signal
                });
                setPostCheck(resp?.data);
                console.log(resp);
                // setLoading(false);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        }
        console.log(postCheck?.code);
        input && checkPost();

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input])

    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();

        const MySubmit = async () => {
            try {
                console.log(code);
                console.log(input);
                setInputLoading(true);
                const res = await axiosPrivate.get(`/add/post?input=${input}`);
                res?.data?.code == 200 && setInpShow(true);
                res?.data?.code == 200 && alert("ورودی در سیستم وجود ندارد و  به صورت خودکار اضافه شد.");
                setInputLoading(false);
                console.log(res);
            } catch (err) {
                console.error(err);
                // navigate('/sign-in', { state: { from: location }, replace: true });
            }
        };

        postCheck?.code == 300 && (input && MySubmit());



        // inqData?.code == 300 && MySubmit();
        // inqData?.code == 200 && setReqCode(200);

        return () => {
            isMounted = false;
            isMounted && controller.abort();
        }
    }, [input, postCheck]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setInpShow(false)
            setInput(inputi);
            setSubAlert(false);
            setCode(input);
            // const response = await axiosPrivate.post(`/add/post`, { input });
            // console.log(response);
            // setResCode(response?.data?.code);
            setSubAlert(true);
            sinInputRef.current.value = '';
            // setInput('');
        } catch (err) {
            console.error(err);
            // navigate('/sign-in', { state: { from: location }, replace: true });
        }
    };

    console.log(searchData);

    return (
        <>
            <Header />
            <div className='grid md:grid-cols-7 w-full pt-20 md:pl-4 md:pr-52 overflow-x-hidden scroll-design h-screen overflow-y-auto'>
                <div className="md:col-span-7 bg-white md:p-4 rounded-md md:rounded-l-md md:rounded-r-none">
                    <span className='fill-gradient-instagram inline-block place-content-center mr-4 text-lg ml-2 align-top text-white '><FontAwesomeIcon icon={faInstagram} /></span>
                    <h5 className="inline-flex items-center">پست‌های اینستاگرام</h5>
                    {!inpShow && <button className={`py-1 px-2 hover:bg-yellow text-white text-sm mr-2 bg-light-green cursor-pointer rounded-md select-none transition-all duration-200 ease-in`} onClick={() => setInpShow(true)}>بررسی شناسه</button>
                    }
                    {
                        inpShow && <button className={`py-1 px-2 hover:bg-yellow text-white text-sm mr-2 bg-light-green cursor-pointer rounded-md select-none transition-all duration-200 ease-in`} onClick={() => (setInpShow(false), setInput(null))}>بازگشت</button>
                    }
                    {
                        inpShow ?
                            <div className="lg:w-1/2 mt-4 inq-holder">
                                <div className="mx-auto text-center">
                                    <form onSubmit={handleSubmit}>
                                        <label className="ml-2">لینک پست را وارد کنید:</label>
                                        <input type="text" ref={sinInputRef} onChange={(e) => setInputi(e.target.value)} className="border-2 border-bg-light-green w-1/2 h-10 py-2 px-3 mx-auto rounded bg-white transition-all duration-200 ease-in mt-1 focus:shadow-slate-500 focus:shadow-md outline-2 outline-middle-yellow" />
                                        <button type="submit" className="py-2 px-4 h-10 subBut text-white hover:bg-yellow bg-light-green rounded mr-2 mt-2 cursor-pointer">ثبت</button>
                                    </form>
                                </div>
                                {inputLoading && <div className='flex mt-4'><p className=' text-right text-lg mt-2 pr-6'>در حال بررسی...</p></div>}
                            </div>
                            : (
                                !input ? <section className='news-container'>
                                    <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md p-4">
                                        {loading ? <div className='flex'><p className=' text-right text-lg mt-2 pr-6'>در حال بارگذاری...</p></div>
                                            : (searchData?.length
                                                ? searchData?.map((log, i) => (
                                                    log?.status === 1 ?
                                                        <div key={log.post_code} className="w-full lg:h-60 bg-gray-50 transition-all duration-200 ease-in border border-slate-400 shadow-md shadow-slate-300 rounded-md mb-3">
                                                            {openModal &&
                                                                <div className='sliderWrap'>
                                                                    <FontAwesomeIcon icon={faXmark} className='btnClose' onClick={handleCloseModal} />
                                                                    <div className='fullScreenImage'>
                                                                        <p className="text-center text-white w-2/3 h-full mx-auto mt-20">
                                                                            <p className='flex justify-between border-b text-2xl'><span>متن کامل</span></p>
                                                                            <p className=' overflow-y-auto text-slate-50 text-lg p-6 h-4/5'>{searchData[slideNumber]['caption']}</p>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className='flex flex-col justify-between items-center lg:grid lg:grid-cols-3 w-full h-full'>
                                                                <div className='flex flex-col lg:flex-row items-center justify-center lg:bg-blue-100 lg:border-l-2 lg:border-l-bg-light-green h-full p-1'>
                                                                    <div className="lg:ml-2">
                                                                        {
                                                                            log?.profile_image ? <a href={log?.profile_image} download target="_blank"><img src={log?.profile_image} alt="تصویر پروفایل" width={120} className="rounded-full m-auto" /></a>
                                                                                : <img src={contactImg} alt="تصویر پروفایل" width={120} className="rounded-full m-auto" />
                                                                        }
                                                                    </div>
                                                                    <div className=''>
                                                                        <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                            <div className='news-card-details flex justify-start mx-1'>
                                                                                <div className='news-card-time text-gray-600'>{log.comment_count}</div>
                                                                                <div className='news-card-calIcon mr-1 text-blue-600'><FontAwesomeIcon icon={faMessage} /></div>
                                                                            </div>
                                                                            <div className='news-card-details flex justify-start mx-1'>
                                                                                <div className='news-card-time text-gray-600'>{log.like_count}</div>
                                                                                <div className='news-card-calIcon mr-1 text-red-600'><FontAwesomeIcon icon={faHeart} /></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                            <div className='news-card-details flex justify-start'>
                                                                                <div className='news-card-time text-gray-600'>{log?.owner?.username}</div>
                                                                                <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faUser} /></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                            <div className='news-card-details flex justify-start'>
                                                                                <div className='news-card-time text-gray-600'>{log?.owner?.full_name}</div>
                                                                                <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faUserCircle} /></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                            <div className='news-card-details flex justify-start'>
                                                                                <div className='news-card-time text-gray-600'>{log.created_at}</div>
                                                                                <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faCalendar} /></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                            <div className='news-card-details flex justify-start'>
                                                                                <div className='news-card-time text-gray-600'>{log.post_id}</div>
                                                                                <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faHashtag} /></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    log?.status === 1 && <div className='news-card-content py-3 px-6 lg:col-span-2'>
                                                                        <div className='flex'>
                                                                            <p className='news-content line-clamp-4 text-ellipsis'>
                                                                                {log.caption}
                                                                            </p>
                                                                        </div>
                                                                        <div className='relative flex items-center justify-center lg:justify-start'>
                                                                            <p onClick={() => handleOpenModal(i)} className='py-1 px-2 mb-0 mx-1 text-center w-36 hover:bg-yellow text-white text-sm bg-dark-blue rounded select-none transition-all duration-200 ease-in cursor-pointer'>مشاهده متن کامل</p>
                                                                            <div className="my-1 md:my-0 md:mx-1">
                                                                                <a href={`https://www.instagram.com/reel/${log?.post_code}/`} target="_blank">
                                                                                    <button className={`py-1 px-2 w-32 hover:bg-yellow text-white text-sm bg-dark-blue rounded select-none transition-all duration-200 ease-in`}>{`مشاهده ${log?.type === "reel" ? "reel" : "post"}`}</button>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {/* <h6 className="m-0 ">{log['owner.username']}
                                                                    </h6>
                                                                    <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                                                                        شناسه: {log.post_code}
                                                                    </p> */}

                                                            </div>
                                                        </div> : log?.status === 2 ? <div key={log.post_code} className="w-full h-60 flex justify-center items-center bg-dark-blue transition-all duration-200 ease-in border border-slate-400 shadow-md shadow-slate-300 rounded-md mb-3">
                                                            <div className='text-center text-white'>
                                                                {`پستی که با کد ${log?.post_code} وارد سیستم کرده‌اید، وجود ندارد.`}
                                                            </div>
                                                        </div> : <div key={log.post_code} className="w-full h-60 flex justify-center items-center bg-middle-green transition-all duration-200 ease-in border border-slate-400 shadow-md shadow-slate-300 rounded-md mb-3">
                                                            <div className='text-center text-white'>
                                                                {`استعلام پستی که با کد ${log?.post_code} وارد سیستم کرده‌اید، تکمیل نشده است.`}
                                                            </div>
                                                        </div>
                                                )) : <p>موردی جهت نمایش وجود ندارد</p>)}
                                    </div>
                                    {searchData?.length >= 10 && pageNum?.length > 1
                                        ? <div className='flex justify-between'>
                                            <div className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-8 rounded-md text-white'>صفحه<span className='mr-1'>{pageNumber}</span></div>
                                            <ul className='flex ml-8 my-0'>
                                                <li className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-1 rounded-md text-white' onClick={decree}>قبلی</li>
                                                {
                                                    pageNum?.map((num, i) => (
                                                        <li key={i} className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-1 rounded-md text-white' onClick={(i) => { setPageNumber(num.number); }}>{num.number}</li>
                                                    ))}
                                                <li className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-1 rounded-md text-white' onClick={increa}>بعدی</li>
                                            </ul></div>

                                        : ''
                                    }
                                </section> : (
                                    postCheck?.code == 200 && <section className='news-container'>
                                        <div className="news-card grid grid-cols-1 gap-x-4 mt-2 rounded-md p-4">
                                            {loading ? <div className='flex'><p className=' text-right text-lg mt-2 pr-6'>در حال بارگذاری...</p></div>
                                                : (postCheck ? (<>{postCheck?.status === 1 &&
                                                    <div key={postCheck?.data[0]?.post_code} className="w-full lg:h-60 bg-gray-50 transition-all duration-200 ease-in border border-slate-400 shadow-md shadow-slate-300 rounded-md mb-3">
                                                        {openModal &&
                                                            <div className='sliderWrap'>
                                                                <FontAwesomeIcon icon={faXmark} className='btnClose' onClick={handleCloseModal} />
                                                                <div className='fullScreenImage'>
                                                                    <p className="text-center text-white w-2/3 h-full mx-auto mt-20">
                                                                        <p className='flex justify-between border-b text-2xl'><span>متن کامل</span></p>
                                                                        <p className=' overflow-y-auto text-slate-50 text-lg p-6 h-4/5'>{postCheck?.data[0]?.caption}</p>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className='flex flex-col justify-between items-center lg:grid lg:grid-cols-3 w-full h-full'>
                                                            <div className='flex flex-col lg:flex-row items-center justify-center lg:bg-blue-100 lg:border-l-2 lg:border-l-bg-light-green h-full p-1'>
                                                                <div className="lg:ml-2">
                                                                    {
                                                                        postCheck?.data[0]?.profile_image ? <a href={postCheck?.data[0]?.profile_image} download target="_blank"><img src={postCheck?.data[0]?.profile_image} alt="تصویر پروفایل" width={120} className="rounded-full m-auto" /></a>
                                                                            : <img src={contactImg} alt="تصویر پروفایل" width={120} className="rounded-full m-auto" />
                                                                    }
                                                                </div>
                                                                <div className=''>
                                                                    <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                        <div className='news-card-details flex justify-start mx-1'>
                                                                            <div className='news-card-time text-gray-600'>{postCheck?.data[0]?.comment_count}</div>
                                                                            <div className='news-card-calIcon mr-1 text-blue-600'><FontAwesomeIcon icon={faMessage} /></div>
                                                                        </div>
                                                                        <div className='news-card-details flex justify-start mx-1'>
                                                                            <div className='news-card-time text-gray-600'>{postCheck?.data[0]?.like_count}</div>
                                                                            <div className='news-card-calIcon mr-1 text-red-600'><FontAwesomeIcon icon={faHeart} /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                        <div className='news-card-details flex justify-start'>
                                                                            <div className='news-card-time text-gray-600'>{postCheck?.data[0]?.owner?.username}</div>
                                                                            <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faUser} /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                        <div className='news-card-details flex justify-start'>
                                                                            <div className='news-card-time text-gray-600'>{postCheck?.data[0]?.owner?.full_name}</div>
                                                                            <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faUserCircle} /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                        <div className='news-card-details flex justify-start'>
                                                                            <div className='news-card-time text-gray-600'>{postCheck?.data[0]?.created_at}</div>
                                                                            <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faCalendar} /></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex justify-center mx-auto my-2 md:my-0'>
                                                                        <div className='news-card-details flex justify-start'>
                                                                            <div className='news-card-time text-gray-600'>{postCheck?.data[0]?.post_id}</div>
                                                                            <div className='news-card-calIcon mr-1 text-gray-600'><FontAwesomeIcon icon={faHashtag} /></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                postCheck?.data[0]?.status === 1 && <div className='news-card-content py-3 px-6 lg:col-span-2'>
                                                                    <div className='flex'>
                                                                        <p className='news-content line-clamp-4 text-ellipsis'>
                                                                            {postCheck?.data[0]?.caption}
                                                                        </p>
                                                                    </div>
                                                                    <div className='relative flex items-center justify-center lg:justify-start'>
                                                                        <p onClick={() => handleOpenModalSingle()} className='py-1 px-2 mb-0 mx-1 text-center w-36 hover:bg-yellow text-white text-sm bg-dark-blue rounded select-none transition-all duration-200 ease-in cursor-pointer'>مشاهده متن کامل</p>
                                                                        <div className="my-1 md:my-0 md:mx-1">
                                                                            <a href={`https://www.instagram.com/reel/${postCheck?.data[0]?.post_code}/`} target="_blank">
                                                                                <button className={`py-1 px-2 w-32 hover:bg-yellow text-white text-sm bg-dark-blue rounded select-none transition-all duration-200 ease-in`}>{`مشاهده ${postCheck?.data[0]?.type === "reel" ? "reel" : "post"}`}</button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {/* <h6 className="m-0 ">{postCheck?.data[0]?.'owner.username']}
                                                                    </h6>
                                                                    <p className="m-0 text-sm cursor-pointer mt-1 text-gray-600">
                                                                        شناسه: {postCheck?.data[0]?.post_code}
                                                                    </p> */}

                                                        </div>
                                                    </div>}
                                                    {
                                                        postCheck?.status === 0 && <div key={postCheck?.data[0]?.post_code} className="w-full h-60 flex justify-center items-center bg-middle-green transition-all duration-200 ease-in border border-slate-400 shadow-md shadow-slate-300 rounded-md mb-3">
                                                            <div className='text-center text-white'>
                                                                {`استعلام پستی که با کد ${postCheck?.data[0]?.post_code} وارد سیستم کرده‌اید، تکمیل نشده است.`}
                                                            </div>
                                                        </div>
                                                    }
                                                    {postCheck?.status === 2 && <div key={postCheck?.data[0]?.post_code} className="w-full h-60 flex justify-center items-center bg-dark-blue transition-all duration-200 ease-in border border-slate-400 shadow-md shadow-slate-300 rounded-md mb-3">
                                                        <div className='text-center text-white'>
                                                            {`پستی که با کد ${postCheck?.data[0]?.post_code} وارد سیستم کرده‌اید، وجود ندارد.`}
                                                        </div>
                                                    </div>}
                                                </>
                                                ) : <p>موردی جهت نمایش وجود ندارد</p>)}
                                        </div>
                                        {searchData?.length >= 10 && pageNum?.length > 1
                                            ? <div className='flex justify-between'>
                                                <div className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-8 rounded-md text-white'>صفحه<span className='mr-1'>{pageNumber}</span></div>
                                                <ul className='flex ml-8 my-0'>
                                                    <li className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-1 rounded-md text-white' onClick={decree}>قبلی</li>
                                                    {
                                                        pageNum?.map((num, i) => (
                                                            <li key={i} className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-1 rounded-md text-white' onClick={(i) => { setPageNumber(num.number); }}>{num.number}</li>
                                                        ))}
                                                    <li className='flex text-center items-center leading-10 bg-middle-blue hover:bg-dark-blue px-3 mr-1 rounded-md text-white' onClick={increa}>بعدی</li>
                                                </ul></div>

                                            : ''
                                        }
                                    </section>
                                )
                            )
                    }
                </div>
            </div>
        </>

    )
}

export default InstaPost